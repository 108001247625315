import { CModal, CModalHeader, CModalTitle, CModalFooter, CButton, CModalBody } from "@coreui/react";
import { ApiResponse } from "api/clients";
import { useEffect, useState } from "react";
import ApiResponseAlert from "./ApiResponseAlert";

interface ToggleEnabledDialogProps {
  onConfirm: (notes: string) => void;
  onCancel: () => void;
  show: boolean;
  title: string;
  errorMessage?: ApiResponse;
}

export default function ToggleEnabledDialog(
  props: ToggleEnabledDialogProps
) {

  const [notes, setNotes] = useState("")
  const { title, show, onConfirm, onCancel, errorMessage } = props;

  useEffect(() => {
    setNotes("")
  }, [show])

  return (
    <CModal
      backdrop={true}
      show={show}
    >
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {errorMessage !== undefined && (
          <ApiResponseAlert data={errorMessage!}></ApiResponseAlert>
        )}
        <label htmlFor="UpdateToggleNotes">Notes</label>
        <textarea rows={6} id="UpdateToggleNotes" className="form-control" value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onCancel}>Cancel</CButton>
        <CButton color="primary" onClick={() => onConfirm(notes)}>Save changes</CButton>
      </CModalFooter>
    </CModal>
  );
}
