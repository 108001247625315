import React, { Component, MutableRefObject } from "react";
import { CAlert } from "@coreui/react";
import { ApiResponse, HttpResponseType } from "api/clients";

interface ApiResponseAlertProps {
  data: ApiResponse;
  innerRef?: MutableRefObject<HTMLDivElement | null>;
}

export default class ApiResponseAlert extends Component<ApiResponseAlertProps> {
  getVariant(): string {
    const { responseType } = this.props.data;

    switch (responseType) {
      case HttpResponseType.Success:
        return "success";
      case HttpResponseType.Info:
        return "info";
      case HttpResponseType.Warning:
        return "warning";
      case HttpResponseType.Error:
        return "danger";
    }
  }

  getIcon(): string {
    const { responseType } = this.props.data;

    switch (responseType) {
      case HttpResponseType.Success:
        return "fas fa-check-circle";
      case HttpResponseType.Info:
        return "fas fa-info-circle";
      case HttpResponseType.Warning:
        return "fas fa-exclamation-triangle";
      case HttpResponseType.Error:
        return "fas fa-exclamation-circle";
    }
  }

  render() {
    const { innerRef } = this.props
    const { messages } = this.props.data;

    return (
      <CAlert innerRef={innerRef} color={this.getVariant()} className="response-alert">
        <i className={`alert-icon mr-2 ${this.getIcon()}`}></i>
        {messages?.join("<br />")}
      </CAlert>
    );
  }
}
