import { useEffect } from "react";

import { Breadcrumb, LAYOUT_SET_BREADCRUMBS } from "store/layout/types";
import { useDispatch } from "react-redux";

export interface WrappedPageProps {
  breadcrumbs: Breadcrumb[];
  children: any;
}

export default function WrappedPage(props: WrappedPageProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: LAYOUT_SET_BREADCRUMBS, breadcrumbs: props.breadcrumbs });
  }, [dispatch, props]);

  return props.children;
}
