import * as React from "react";
import { CLink, CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import { connect } from "react-redux";
import { AppState } from "store";
import { LayoutState } from "store/layout/types";

class Breadcrumbs extends React.PureComponent<LayoutState, {}> {
  public state = {};

  public render() {
    const { breadcrumbs } = this.props;

    return (
      <CBreadcrumb className="m-0 border-0">
        <CBreadcrumbItem>
          <CLink to="/">Home</CLink>
        </CBreadcrumbItem>
        {breadcrumbs.map((x, i) => (
          <CBreadcrumbItem key={i} active={i === breadcrumbs.length - 1}>
            {x.to !== undefined && x.to.length > 0 ? (
              <CLink to={x.to}>{x.label}</CLink>
            ) : (
              <span>{x.label}</span>
            )}
          </CBreadcrumbItem>
        ))}
      </CBreadcrumb>
    );
  }
}

export default connect(
  (state: AppState) => ({ ...state.layout }),
  {}
)(Breadcrumbs);
