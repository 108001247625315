import { ICellRendererParams } from "ag-grid-community";
import { SelectOption } from "common/selectOption";

const SelectRenderer = (props: ICellRendererParams) => {
  return <div className="ag-cell-h-full d-flex align-items-center">
      <select className="form-control" value={props.value ?? -1} onChange={(e: any) => {
          (props as any).onUpdate({
            ...props.data,
            [props.colDef!.field!]: !isNaN(parseInt(e.target.value)) && parseInt(e.target.value) > 0 ? parseInt(e.target.value) : undefined
          })
        }} >
          {(props as any).options?.map((x: SelectOption) => <option key={x.label} value={x.value}>{x.label}</option>)}
      </select>
    </div>
}

export default SelectRenderer