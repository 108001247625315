import { CSwitch } from "@coreui/react";
import { ICellRendererParams } from "ag-grid-community";

const UpdateEnabledToggleRenderer = (props: ICellRendererParams) => {
  return <div className="ag-cell-h-full d-flex align-items-center">
    <CSwitch color="primary" checked={props.data.enabled} shape="pill" onChange={(e: any) => {
        e.preventDefault();
        (props as any).onToggle(props.data)
      }} />
    </div>
}

export default UpdateEnabledToggleRenderer