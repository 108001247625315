import { useEffect, useRef, useState } from "react";
import {
  LocationsClient,
  KitTypesClient,
  InventoriesClient,
  ApiResponse,
  LocationDTO,
  KitTypeDTO,
  ReceiveShipmentDTO,
  HttpResponseType,
  ApiException
  //HttpResponseType
} from "api/clients";
import { CCard, CCardBody } from "@coreui/react";
import ApiResponseAlert from "components/common/ApiResponseAlert";
import WrappedPage from "components/pages/WrappedPage";
import SCardHeader from "components/common/CardHeader";
import Loading from "components/common/Loading";
// import { useHistory } from "react-router";
import { Checkbox, ManagedForm, WrappedDatePicker, WrappedInput, WrappedSelect, WrappedTextbox } from "components/common/Form";
import { getReasonPhrase } from "http-status-codes";
import { SelectOption, SelectOptionGroup } from "common/selectOption"
import { OtherLocations, generateLocationOptions } from "common/locations"
import { useForm } from "react-hook-form";
import { DateTime } from "luxon"

export default function ReceiveShipment() {
  const alertRef = useRef<null | HTMLDivElement>(null)
  const executeScrollToAlert = () => { 
    if(alertRef.current !== null) {
      const yOffset = -75; 
      const y = alertRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({top: y, behavior: 'smooth'});
    } 
  }

  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  //const [shipment, setShipment] = useState(new PickUpShipmentDTO())
  const [locations, setLocations] = useState([] as SelectOptionGroup[])
  const [receivedFromLocations, setReceivedFromLocations] = useState([] as SelectOptionGroup[])
  const [kitTypes, setKitTypes] = useState([] as SelectOption[])
  const [inventoryClient] = useState(new InventoriesClient())
  const [locationsClient] = useState(new LocationsClient())
  const [kitTypesClient] = useState(new KitTypesClient())
  const [numDetails, setNumDetails] = useState(3);
  const [actionResult, setActionResult] = useState(
    undefined as ApiResponse | undefined
  );

  let breadcrumbs = [
    {
      to: "/inventory",
      label: "Inventory"
    },
    {
      label: "Receive Shipment"
    }
  ];

  // default to have 3 details sections show up on the form
  let defaultShipment = ReceiveShipmentDTO.fromJS({
    dateReceived: DateTime.local()
  });
  const methods = useForm({ defaultValues: defaultShipment, shouldFocusError: false });

  useEffect(() => {
    async function getData() {

      let locationsPromise = locationsClient
        .getAll()
        .then((result: ApiResponse) => {
          var locations = result.data! as LocationDTO[]

          setLocations(generateLocationOptions(locations, false))
          setReceivedFromLocations(generateLocationOptions(locations, true))
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting locations: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      let kitTypesPromise = kitTypesClient
        .getAll()
        .then((result: ApiResponse) => {
          setKitTypes((result.data! as KitTypeDTO[]).filter(x => x.enabled).map(x => { return { label: x.name, value: x.id } }))
        })
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting kit types: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      await Promise.all([ locationsPromise, kitTypesPromise ])

      setReady(true);
    }

    getData();
  }, [locationsClient, kitTypesClient]);

  function onSubmit(data: ReceiveShipmentDTO) {
    setSaving(true)

    // Filter out any lot numnber rows that aren't fully filled in. Also convert datetimes messed up by hook form back to proper objects
    data.dateReceived = DateTime.fromMillis((data.dateReceived as any).ts)

    data.details = data.details.filter(x => x.quantity > 0 && x.lotNumber.length > 0 && x.expiryDate !== undefined)
    data.details.forEach(x => {
      if(x.expiryDate !== undefined) {
        x.expiryDate = DateTime.fromMillis((x.expiryDate as any).ts)
      }
    })

    if(data.receivedFromLocationID === OtherLocations.PharmacyDotCa.value) {
      data.receivedFromLocationName = OtherLocations.PharmacyDotCa.label
    }

    inventoryClient
      .receiveShipment(data)
      .then((response: ApiResponse) => {
        setActionResult(response);
      })
      .catch((reason: any) => {

        if(reason.title !== undefined && reason.title.length > 0) {
          setActionResult(
            ApiResponse.fromJS({
              messages: [reason.title],
              responseType: HttpResponseType.Error
            })
          );
        } else {
          setActionResult(
            ApiResponse.fromJS({
              messages: [reason],
              responseType: HttpResponseType.Error
            })
          );
        }
        
        console.error(reason);
      })
      .finally(() => {
        executeScrollToAlert();
        setSaving(false)
      });
  }

  return (
    <WrappedPage breadcrumbs={breadcrumbs}>
      {!ready && <Loading color="primary" label="Loading Receiving Screen"></Loading>}
      {ready && <CCard>
        <SCardHeader title="Inventory - Receive Shipment"></SCardHeader>
        <CCardBody>
          {actionResult !== undefined && (
            <ApiResponseAlert data={actionResult} innerRef={alertRef}></ApiResponseAlert>
          )}
          <ManagedForm methods={methods} onSubmit={onSubmit} autoComplete="off">
            <h4>Details</h4>
            <div className="row">
              <div className="col-12 col-lg-4">
                <WrappedSelect name="locationID" label="Location" options={locations} rules={{required: true}} />
              </div>
              <div className="col-12 col-lg-4">
                <WrappedSelect name="kitTypeID" label="Kit Type" options={kitTypes} rules={{required: true}} />
              </div>
              <div className="col-12 col-lg-4">
                <WrappedDatePicker name="dateReceived" label="Date Received" rules={{required: true}} />
              </div>
              <div className="col-12 col-lg-4">
                <WrappedSelect name="receivedFromLocationID" label="Received From" options={receivedFromLocations} rules={{required: true}} />
              </div>
              <div className="col-12 col-lg-4">
                <WrappedInput 
                  type="text" 
                  name="ReceivedFromLocationName" 
                  label="Other Location"
                  disabled={methods.watch("receivedFromLocationID") !== OtherLocations.Other.value}
                  rules={{ 
                    required: methods.watch("receivedFromLocationID") === OtherLocations.Other.value
                  }} />
              </div>
            </div>
            <h4 className="py-3">Lot Codes</h4>
            <div className="list-group list-group-flush">
              {[...Array(numDetails)].map((x, i) => <div key={`lotCodeDetail${i}`} className="list-group-item">
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <WrappedInput type="number" name={`details[${i}].quantity`} label="Quantity" rules={{required: i === 0, min: { value: 1, message: "Minimum quantity must be greater than 0" }}} />
                  </div>
                  <div className="col-12 col-lg-4">
                    <WrappedInput type="text" name={`details[${i}].lotNumber`} label="Lot Number" rules={{required: i === 0 }} />
                  </div>
                  <div className="col-12 col-lg-4">
                    <WrappedDatePicker name={`details[${i}].expiryDate`} label="Expiry Date" rules={{required: i === 0 }} />
                  </div>
                  <div className="col-12 col-lg-4">
                    <WrappedInput type="text" name={`details[${i}].qrCodeRange`} label="QR Codes (Range)" id={`details[${i}].qrCodeRange`} />
                  </div>
                  <div className="col-12 col-lg-8 d-flex">
                    <Checkbox name={`details[${i}].studyPamphletProvided`} label="Study Pamphlet Provided" labelClasses="mt-2" checked={false} id={`details[${i}].studyPamphletProvided`} />
                  </div>
                </div>
              </div>)}
            </div>
            <div className="formGroup text-right">
              <div className="btn-group">
                <button type="button" className="btn btn-outline-primary" onClick={() => setNumDetails(numDetails + 1)}><i className="cil-plus-circle"></i> Add Lot Code</button>
                <button type="button" className="btn btn-outline-primary" disabled={numDetails === 1} onClick={() => setNumDetails(numDetails - 1)}><i className="cil-minus-circle"></i> Remove Lot Code</button>
              </div>
            </div>

            <WrappedTextbox name="notes" label="Notes" />

            <div className="text-right">
              <button className="btn btn-primary" type="submit" disabled={saving}>{!saving ? "Submit" : "Saving..."}</button>
            </div>
          </ManagedForm>
        </CCardBody>
      </CCard>}
    </WrappedPage>
  );
}
