import { useMemo } from "react";
import { ColDef, ColGroupDef, GridOptions, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import UpdateEnabledToggleRenderer from "./cellRenderers/UpdateEnabledToggleRenderer";
import SelectRenderer from "./cellRenderers/SelectRenderer";
import InputRenderer from "./cellRenderers/InputRenderer";

type ISGrid = {
    domLayout?: "normal" | "autoHeight" | "print" | undefined
    onGridReady?: (event: GridReadyEvent) => void
    gridRef?: any
    getRowNodeId?: (data: any) => string
    columnDefs: (ColDef | ColGroupDef)[]
    data: any[]
    options?: GridOptions;
    defaultColDef?: ColDef
    frameworkComponents?: {
        [p: string]: {
            new (): any;
        };
    } | any;
};

export type SCol = {
    field: string;
}

export default function SGrid({ 
    options = undefined, 
    defaultColDef = undefined, 
    frameworkComponents = undefined, 
    columnDefs, 
    data, 
    gridRef, 
    onGridReady, 
    getRowNodeId,
    domLayout = "normal"
}: ISGrid) {

    const internalDefaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        filter: false,
        flex: 1,
        minWidth: 100,
        ...defaultColDef
    }), [defaultColDef]);

    return (
        <AgGridReact
            components={{
                ...frameworkComponents,
                updateEnabledToggleRenderer: UpdateEnabledToggleRenderer,
                selectRenderer: SelectRenderer,
                inputRenderer: InputRenderer
            }}
            domLayout={domLayout}
            getRowNodeId={getRowNodeId}
            ref={gridRef}
            onGridReady={onGridReady}
            gridOptions={options}
            className="ag-theme-alpine"
            animateRows={true}
            defaultColDef={internalDefaultColDef}
            columnDefs={columnDefs}
            rowData={data}
            paginationAutoPageSize={true}
            pagination={true}
        />
    )
}