import UserMenu from "components/app/navigation/UserMenu";
import Breadcrumbs from "components/app/navigation/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { CToggler } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { LAYOUT_SET_SIDEBAR_SHOW } from "store/layout/types";
import { AppState } from "store";

interface NavBarProps {
  ready: boolean;
}

const NavBar = ({ ready }: NavBarProps) => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(
    (state: AppState) => state.layout.sidebarShow
  );

  return (
    <>
      {ready && (
        <>
          <CToggler
            inHeader={true}
            className="ml-3"
            onClick={() =>
              dispatch({
                type: LAYOUT_SET_SIDEBAR_SHOW,
                sidebarShow: !sidebarShow
              })
            }
          >
            <CIcon content={cilMenu} size="xl" />
          </CToggler>
          <Breadcrumbs></Breadcrumbs>
          <UserMenu></UserMenu>
        </>
      )}
    </>
  );
};

export default NavBar;
