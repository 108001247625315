import { CNav, CNavItem } from '@coreui/react'
import { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class FulfilmentTabs extends Component<any, any> {

  render() {
    return (
      <CNav variant="tabs" className="mb-3">
        <CNavItem>
          <NavLink className="nav-link" to="/fulfillment/fulfill-delivery-kits">Fulfillment of Delivery Kits</NavLink>
        </CNavItem>
        <CNavItem>
          <NavLink className="nav-link" to="/fulfillment/return-shipped-kits" >Kits Marked Shipped</NavLink>
        </CNavItem>
        <CNavItem>
          <NavLink className="nav-link" to="/fulfillment/orders-archived" >Orders Archived</NavLink>
        </CNavItem>
      </CNav>
      
    )
  }
}