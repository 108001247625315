import { useRef, useState } from "react";
import {
  ApiResponse,
  ApiException,
  HttpResponseType,
  LocationKitTypeDescriptorDTO,
  LocationsClient,
  HttpStatusCode,
  UpdateLocationKitTypeDTO
} from "api/clients";
import { getReasonPhrase } from "http-status-codes";
import { Link } from "react-router-dom";
import ApiResponseAlert from "components/common/ApiResponseAlert";
import ToggleEnabledDialog from "components/common/ToggleEnabledDialog";
import SGrid from "components/common/SGrid";
import { ColDef, GridOptions, GridReadyEvent, ICellRendererParams, RowClassParams, ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import { SelectOption } from "common/selectOption";
import { AgGridReact } from 'ag-grid-react/lib/agGridReact'

type LocationKitTypeGridProps = {
  locationId?: number
  kitTypeId?: number
  domLayout?: "normal" | "autoHeight" | "print" | undefined
};

const EditLocationRenderer = (props: ICellRendererParams) => {
  return <div className="ag-cell-h-full d-flex align-items-center justify-content-end">
    <div className="btn-group btn-group-sm">
      {(props as any).locationId !== props.data.locationID.toString() && <Link
        to={"/locations/edit/" + props.data.locationID}
        className="btn btn-outline-primary"
      >
        <i className="fas fa-pencil-alt"></i>
      </Link>}
    </div>
  </div>
}

export default function LocationKitTypeGrid({ locationId, kitTypeId, domLayout = "normal" }: LocationKitTypeGridProps) {

  const gridRef = useRef<AgGridReact>(null);
  const [ready, setReady] = useState(false)
  const [locationClient] = useState(new LocationsClient())
  const [actionResult, setActionResult] = useState<ApiResponse | undefined>(undefined)
  const [showUpdateEnabled, setShowUpdateEnabled] = useState(false)
  const [updateKitType, setUpdateKitType] = useState<LocationKitTypeDescriptorDTO | undefined>(undefined)
  const [updateKitTypeError, setUpdateKitTypeError] = useState<ApiResponse | undefined>(undefined)
  const [weekOptions] = useState<SelectOption[]>([
    { label: "Custom", value: -1 },
    { label: "1 Week", value: 1 },
    { label: "2 Weeks", value: 2 },
    { label: "3 Weeks", value: 3 },
    { label: "4 Weeks", value: 4 },
    { label: "5 Weeks", value: 5 },
    { label: "6 Weeks", value: 6 },
    { label: "7 Weeks", value: 7 },
    { label: "8 Weeks", value: 8 }
  ])

  const onGridReady = (params: GridReadyEvent) => {
    getInventories(true)
  }

  const getInventories = (useLoadingScreen: boolean) => {
    if(useLoadingScreen) {
      setReady(false);
    }

    locationClient
      .getKitTypeData(locationId, kitTypeId)
      .then((inventory: LocationKitTypeDescriptorDTO[]) => {
        setReady(true)
        gridRef.current!.api.setRowData(inventory)
        gridRef.current!.api.refreshCells()
      })
      .catch((reason: ApiException) => {
        setActionResult(ApiResponse.fromJS({
            responseType: HttpResponseType.Error,
            messages: [
              "Error getting inventories: " + getReasonPhrase(reason.status)
            ]
          }))
        setReady(true)
      });
  }

  let getRowNodeId = (data: LocationKitTypeDescriptorDTO) => `${data.locationID}-${data.kitTypeID}`

  let toggleKitTypeEnabled = (kitType?: LocationKitTypeDescriptorDTO): void => {
    if(!showUpdateEnabled) {
      
      setShowUpdateEnabled(true)
      setUpdateKitType(kitType)
      setUpdateKitTypeError(undefined)

    } else {
      setShowUpdateEnabled(false)
      setUpdateKitType(undefined)
      setUpdateKitTypeError(undefined)
    }
  }

  let onSaveKitTypeEnabled = (notes: string) => {
    onSaveKitType(updateKitType!, notes)
  }

  let onSaveKitType = (kitType: LocationKitTypeDescriptorDTO, notes?: string) => {

    let row = gridRef.current!.api.getRowNode(getRowNodeId(kitType))
    row!.setData(kitType)

    // Don't save on custom burn rate weeks unless there's a safety stock set
    if(kitType.burnRateWeeks === undefined && (kitType.safetyStockOverride === undefined || kitType.safetyStockOverride === null)) {
      return;
    }

    locationClient
      .putKitType(kitType.locationID, kitType.kitTypeID, UpdateLocationKitTypeDTO.fromJS({ ...kitType, notes }))
      .then((resp: ApiResponse) => {
        setShowUpdateEnabled(false)
        setUpdateKitType(undefined)
        setUpdateKitTypeError(undefined)
        setActionResult(resp.responseCode === HttpStatusCode.OK 
          ? ApiResponse.fromJS({
              responseType: HttpResponseType.Success,
              messages: [
                `${kitType.locationName} ${kitType.kitTypeName} Updated`
              ]
            })
          : ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: resp.messages
            }))

        row!.setData(resp.data!)
      })
      .catch((exception: ApiException) => {
        console.log(exception)
        setUpdateKitTypeError(ApiResponse.fromJS({
          responseType: HttpResponseType.Error,
          messages: [
            `Error updating location kit type ${kitType.locationID}, ${kitType.kitTypeID}: ${getReasonPhrase(exception.status)}`
          ]
        }))
      });
    
  }

  const [gridOptions] = useState<GridOptions>({
    suppressRowHoverHighlight: true,
    suppressCellSelection: true,
    rowClassRules: {
      'ag-row-warning': (params: RowClassParams) => {
        let expiry = params.data.nextExpiry
        let twoMonths = new Date()
        twoMonths.setMonth(twoMonths.getMonth() + 2);

        return expiry !== undefined && expiry !== null && expiry < twoMonths
      }
    }
  })

  const [colDefs] = useState<ColDef[]>([
    { headerName: 'Location', flex: 2, filter: 'set', initialSort: 'asc', initialSortIndex: 2, field: 'locationName' },
    { headerName: 'Kit Type', flex: 1, filter: true, field: 'kitTypeName' },
    { 
      headerName: 'Quantity', 
      flex: 0, 
      width: 150, 
      filter: 'number', 
      initialSort: 'desc', 
      initialSortIndex: 1, 
      field: 'quantity',
      cellClass: params => {
        let rowData = (params.data as LocationKitTypeDescriptorDTO)
        let safetyStock = rowData.burnRateWeeks !== undefined && rowData.burnRateWeeks !== null 
          ? rowData.burnRateAvg * rowData.burnRateWeeks 
          : rowData.safetyStockOverride ?? 0
        return rowData.quantity < safetyStock ? 'ag-cell-danger' : '';
      },
    },
    { 
      headerName: 'Next Expiry', 
      flex: 1, 
      filter: 'date', 
      field: 'nextExpiry', 
      valueFormatter: (params: ValueFormatterParams) => params.value !== undefined && params.value !== null ? 
        typeof(params.value.toLocaleDateString) ==='function' 
          ? params.value.toLocaleDateString('en-ca') 
          : new Date(params.value).toLocaleDateString('en-ca')
        : '',
      cellClass: 'ag-cell-light'
    },
    { 
      cellRenderer: "selectRenderer", 
      cellRendererParams: {
        options: weekOptions,
        onUpdate: onSaveKitType
      },
      headerName: 'Burn Rate', 
      flex: 0, 
      width: 200, 
      field: 'burnRateWeeks',
      cellClass: 'ag-cell-light'
    },
    { 
      headerName: 'Safety Stock', 
      flex: 0, 
      width: 150,
      valueGetter: (params: ValueGetterParams) => {
        let rowData = params.data as LocationKitTypeDescriptorDTO
        return rowData.burnRateWeeks !== undefined && rowData.burnRateWeeks !== null 
          ? rowData.burnRateAvg * rowData.burnRateWeeks 
          : rowData.safetyStockOverride ?? 0
      },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        let rowDataA = nodeA.data as LocationKitTypeDescriptorDTO
        let rowDataB = nodeB.data as LocationKitTypeDescriptorDTO
        let isBelowSafetyStockA = rowDataA.quantity < valueA
        let isBelowSafetyStockB = rowDataB.quantity < valueB

        if (isBelowSafetyStockA === isBelowSafetyStockB) {
          if(valueA === valueB) return 0;
          return valueA > valueB ? 1 : -1;
        }

        return (isBelowSafetyStockA) ? -1 : 1;
      },
      cellRendererSelector: (params: ICellRendererParams) => {

        let rowData = params.data as LocationKitTypeDescriptorDTO

        if(rowData.burnRateWeeks === undefined || rowData.burnRateWeeks === null || rowData.burnRateWeeks < 0) {
          return {
              component: "inputRenderer",
              params: {
                type: "number",
                onUpdate: onSaveKitType,
                field: "safetyStockOverride"
              }
          };
        }

        return undefined
      },
      cellClass: 'ag-cell-light'
    },
    { 
      headerName: 'Enabled', 
      field: 'enabled',
      cellRenderer: "updateEnabledToggleRenderer", 
      width: 120,
      flex:0,
      resizable: false,
      cellRendererParams: {
        onToggle: toggleKitTypeEnabled
      }
    },
    { 
      field: '',
      width: 80,
      flex:0,
      sortable: false,
      resizable: false,
      cellRenderer: 'editLocationRenderer',
      cellRendererParams: {
        locationId: locationId
      }
    }
  ])

  return (
    <>
      {ready && actionResult !== undefined && <ApiResponseAlert data={actionResult}></ApiResponseAlert>}
      
      <div style={{border:'3px solid #fff', paddingBottom:'20px'}}>
        <div style={{backgroundColor:'#FADDDD', width:'20%',textAlign:'center', marginLeft:'80%'}}>
           Below Safety Stock
        </div>
       
        <div style={{backgroundColor:'#FEEFD0', width:'20%',textAlign:'center', marginLeft:'80%'}}>
           Close to Expiry
        </div>
      </div>

      <SGrid
        domLayout={domLayout}
        getRowNodeId={(data: any) => getRowNodeId(data as LocationKitTypeDescriptorDTO) }
        gridRef={gridRef}
        onGridReady={onGridReady}
        options={gridOptions}
        frameworkComponents={{ "editLocationRenderer": EditLocationRenderer }}
        columnDefs={colDefs}
        data={[]}
      ></SGrid>
      <ToggleEnabledDialog 
        title={updateKitType !== undefined ? `${updateKitType!.enabled ? "Disable" : "Enable"} ${updateKitType!.locationName} ${updateKitType!.kitTypeName}` : ""}
        onCancel={() => toggleKitTypeEnabled(undefined)} 
        onConfirm={(notes: string) => { 
          updateKitType!.enabled = !updateKitType!.enabled
          onSaveKitTypeEnabled(notes)
        }}
        show={showUpdateEnabled} 
        errorMessage={updateKitTypeError}
      ></ToggleEnabledDialog>
    </>
  )
}