import { useState } from "react";
import SCardHeader from "components/common/CardHeader";
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import KitTypeGrid from "../locations/KitTypeGrid"

type ManageInventoryProps = {};

export default function ManageInventorys(props: ManageInventoryProps) {

  const [actionButtons] = useState([
    {
      to: "/inventory/receive-shipment",
      icon: "fas fa-plus-circle",
      label: "Receive Shipment"
    },
    {
      to: "/inventory/dispose",
      icon: "fas fa-minus-circle",
      label: "Dispose Inventory"
    }
  ])

  return (
    <WrappedPage breadcrumbs={[{ label: "Inventory" }]}>
      <CCard>
        <SCardHeader
          title="Inventory"
          actionButtons={actionButtons}
        ></SCardHeader>
        <CCardBody className="c-body-full">
          <KitTypeGrid></KitTypeGrid>
        </CCardBody>
      </CCard>
    </WrappedPage>
  )
}