import * as React from "react";
import { CSidebarNavItem, CSidebarNavDropdown } from "@coreui/react";
import MenuLink from "types/MenuLink";
import { NavLink } from "react-router-dom";

type NavMenuLinkProps = {
  link: MenuLink;
  level: number;
};

export default class NavMenuLink extends React.PureComponent<
  NavMenuLinkProps,
  {}
> {
  public render() {
    let { link, level } = this.props;
    let nextLevel = level + 1;

    if (level === 0) {
      return (
        <>
          {link.children === undefined ? (
            <CSidebarNavItem>
              <NavLink
                className="c-sidebar-nav-link"
                to={link.children === undefined ? link.url! : ""}
                exact={true}
                id={"menu-link__" + link.id}
                activeClassName="c-active"
              >
                <i className={`c-sidebar-nav-icon ${link.icon}`}></i>
                {link.label}
              </NavLink>
            </CSidebarNavItem>
          ) : (
            <CSidebarNavDropdown
              id={"menu-link__" + link.id}
              data-toggle="dropdown"
              name={link.label}
              fontIcon={link.icon !== undefined ? link.icon : "cil-settings"}
              show={true}
            >
              {link.children.map((x: MenuLink) => (
                <NavMenuLink key={x.id} link={x} level={nextLevel}></NavMenuLink>
              ))}
            </CSidebarNavDropdown>
          )}
        </>
      );
    } else {
      return (
        <CSidebarNavItem>
          <NavLink
            className="c-sidebar-nav-link"
            to={link.url!}
            exact={true}
            activeClassName="c-active"
          >
            <i className={`c-sidebar-nav-icon ${link.icon}`}></i>
            {link.label}
          </NavLink>
          {link.children !== undefined ? (
            <ul
              className={`menu-children ${level === 1 ? "dropdown-item" : ""}`}
            >
              {link.children.map((x: MenuLink) => (
                <li key={x.id}>
                  <NavMenuLink link={x} level={nextLevel}></NavMenuLink>
                </li>
              ))}
            </ul>
          ) : (
            undefined
          )}
        </CSidebarNavItem>
      );
    }
  }
}
