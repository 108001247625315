export interface Breadcrumb {
  label: string;
  to?: string;
}

export interface LayoutState {
  breadcrumbs: Breadcrumb[];
  sidebarShow: boolean;
}

export const LAYOUT_SET_BREADCRUMBS = "LAYOUT_SET_BREADCRUMBS";
export const LAYOUT_SET_SIDEBAR_SHOW = "LAYOUT_SET_SIDEBAR_SHOW";

interface SetBreadcrumbsAction {
  type: typeof LAYOUT_SET_BREADCRUMBS;
  breadcrumbs: Breadcrumb[];
}

interface SetSidebarShowAction {
  type: typeof LAYOUT_SET_SIDEBAR_SHOW;
  sidebarShow: boolean;
}

export type LayoutActionTypes = SetBreadcrumbsAction | SetSidebarShowAction;
