import { Component } from "react";
import {
  IKitTypesClient,
  KitTypesClient,
  ApiException,
  ApiResponse,
  HttpResponseType,
  KitTypeDTO,
  ToggleEnabledDTO,
  HttpStatusCode
} from "api/clients";
import { getReasonPhrase } from "http-status-codes";
import Loading from "components/common/Loading";
import ApiResponseAlert from "components/common/ApiResponseAlert";
import SCardHeader from "components/common/CardHeader";
import { CCard, CCardBody, CSwitch } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import ToggleEnabledDialog from "components/common/ToggleEnabledDialog";

type ManageKitTypesProps = {};

type ManageKitTypesState = {
  ready: boolean;
  kitTypes?: KitTypeDTO[];
  kitTypeClient: IKitTypesClient;
  actionResult?: ApiResponse;
  showUpdateEnabled: boolean;
  updateKitType?: KitTypeDTO;
  updateKitTypeError?: ApiResponse;
};

export default class KitTypesHome extends Component<ManageKitTypesProps, ManageKitTypesState> {
  constructor(props: ManageKitTypesProps) {
    super(props);

    this.state = {
      ready: false,
      kitTypes: undefined,
      kitTypeClient: new KitTypesClient(),
      actionResult: undefined,
      showUpdateEnabled: false,
      updateKitType: undefined,
      updateKitTypeError: undefined
    };
  }

  async componentDidMount() {
    this.getKitTypes();
  }

  getKitTypes = (): void => {
    let { kitTypeClient } = this.state;

    this.setState({ ready: false });

    kitTypeClient
      .getAll()
      .then((response: ApiResponse) => {
        this.setState({ kitTypes: response.data as KitTypeDTO[], ready: true });
      })
      .catch((reason: ApiException) => {
        this.setState({
          actionResult: ApiResponse.fromJS({
            responseType: HttpResponseType.Error,
            messages: [
              "Error trying to list Kit Types: " + getReasonPhrase(reason.status)
            ]
          }),
          ready: true
        });
      });
  };

  toggleKitTypeEnabled = (kitType?: KitTypeDTO): void => {
    if(!this.state.showUpdateEnabled) {
      this.setState({
        showUpdateEnabled: true,
        updateKitType: kitType!,
        updateKitTypeError: undefined
      });
    } else {
      this.setState({
        showUpdateEnabled: false,
        updateKitType: undefined,
        updateKitTypeError: undefined
      });
    }
  }

  onSaveToggleKitTypeEnabled = (notes: string) => {
    let { kitTypeClient, updateKitType } = this.state;

    kitTypeClient
      .postToggleEnabled(updateKitType!.id, ToggleEnabledDTO.fromJS({
        enabled: !updateKitType!.enabled,
        notes: notes
      }))
      .then((resp: ApiResponse) => {
        this.setState({ 
          showUpdateEnabled: false,
          updateKitType: undefined,
          updateKitTypeError: undefined,
          actionResult: resp.responseCode === HttpStatusCode.OK 
            ? ApiResponse.fromJS({
                responseType: HttpResponseType.Success,
                messages: [
                  `${updateKitType!.name} ${!updateKitType!.enabled ? 'Enabled' : 'Disabled'}`
                ]
              })
            : ApiResponse.fromJS({
                responseType: HttpResponseType.Error,
                messages: resp.messages
              })
         });

        this.getKitTypes();
      })
      .catch((exception: ApiException) => {
        this.setState({
          updateKitTypeError: ApiResponse.fromJS({
            responseType: HttpResponseType.Error,
            messages: [
              `Error updating location ${updateKitType!.id}: ${getReasonPhrase(exception.status)}`
            ]
          })
        });
      });
    
  }

  render() {
    let {
      ready,
      kitTypes,
      actionResult
    } = this.state;

    return (
      <WrappedPage breadcrumbs={[{ label: "Kit Types" }]}>
        <CCard>
          <SCardHeader
            title="Manage Kit Types"
          ></SCardHeader>
          <CCardBody>
            {!ready ? (
              <Loading color="primary" label="Loading Kit Types"></Loading>
            ) : null}
            {ready && actionResult !== undefined ? (
              <ApiResponseAlert data={actionResult}></ApiResponseAlert>
            ) : null}
            {ready && kitTypes !== undefined ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Enabled</th>
                  </tr>
                </thead>
                <tbody>
                  {kitTypes.map(x => (
                    <tr key={x.id}>
                      <td>{x.id}</td>
                      <td>{x.name}</td>
                      <td>{x.key}</td>
                      <td>
                        <CSwitch color="primary" checked={x.enabled} shape="pill" onChange={(e: any) => {
                          e.preventDefault();
                          this.toggleKitTypeEnabled(x)
                        }} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
          </CCardBody>
        </CCard>
        <ToggleEnabledDialog 
          title={this.state.updateKitType !== undefined ? `${this.state.updateKitType!.enabled ? "Disable" : "Enable"} ${this.state.updateKitType!.name}` : ""}
          onCancel={() => this.toggleKitTypeEnabled(undefined)} 
          onConfirm={this.onSaveToggleKitTypeEnabled} 
          show={this.state.showUpdateEnabled} 
          errorMessage={this.state.updateKitTypeError}
        ></ToggleEnabledDialog>
      </WrappedPage>
    );
  }
}
