import { Component } from "react";
import { ApplicationPaths } from "components/authorization/ApiAuthorizationConstants";
import { CNavLink, CHeaderNav, CNavItem } from "@coreui/react";
import authService from "components/authorization/AuthorizeService";

type UserMenuState = {
  isAuthenticated: boolean
}


export default class UserMenu extends Component<{}, UserMenuState> {

  constructor(props: any) {
    super(props)

    this.state = {
      isAuthenticated: false
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ isAuthenticated: await authService.isAuthenticated() });
  }

  render() {
    const { isAuthenticated } = this.state
    const logoutPath = `${ApplicationPaths.LogOut}`;

    return !isAuthenticated ? (
      <></>
    ) : (
      <CHeaderNav className="user-menu ml-auto px-3">
        <CNavItem>
          <CNavLink to={logoutPath}>Logout</CNavLink>
        </CNavItem>
      </CHeaderNav>
    );
  }
}
