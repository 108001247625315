import { ICellRendererParams } from "ag-grid-community";
import { ChangeEvent, useState } from "react";

const InputRenderer = (props: ICellRendererParams) => {

  let [timer, setTimer] = useState<any>(undefined)

  let localOnUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    if(timer !== undefined) {
      clearTimeout(timer)
    }

    let field: string = props.colDef!.field ?? (props as any).field as string

    setTimer(setTimeout(() => {
      (props as any).onUpdate({
        ...props.data,
        [field]: !isNaN(parseInt(event.target.value)) && parseInt(event.target.value) > 0 ? parseInt(event.target.value) : undefined
      })

      clearTimeout(timer)
    }, 750))
  }

  return <div className="ag-cell-h-full d-flex align-items-center">
      <input className="form-control" type={(props as any).type} defaultValue={props.value} onChange={localOnUpdate} />
    </div>
}

export default InputRenderer