export default class MenuLink {
    id!: number;
    label?: string | undefined;
    url?: string | undefined;
    icon: string | undefined;
    parentId?: number | undefined;
    children?: MenuLink[] | undefined;

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.label = _data["label"];
            this.url = _data["url"];
            this.icon = _data["icon"];
            this.parentId = _data["parentId"];
            if (Array.isArray(_data["children"])) {
            this.children = [] as any;
            for (let item of _data["children"])
                this.children!.push(MenuLink.fromJS(item));
            }
        }
    }

    static fromJS(data: any): MenuLink {
        data = typeof data === "object" ? data : {};
        let result = new MenuLink();
        result.init(data);
        return result;
    }
}  