import initialState from "./state";

import {
  LayoutState,
  LayoutActionTypes,
  LAYOUT_SET_BREADCRUMBS,
  LAYOUT_SET_SIDEBAR_SHOW
} from "./types";

export default function layoutReducer(
  state = initialState,
  action: LayoutActionTypes
): LayoutState {
  switch (action.type) {
    case LAYOUT_SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.breadcrumbs
      };
    case LAYOUT_SET_SIDEBAR_SHOW:
      return {
        ...state,
        sidebarShow: action.sidebarShow
      };
    default:
      return state;
  }
}
