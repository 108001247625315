import {
  LocationDTO,
  LocationsClient,
  ApiResponse,
  ApiException,
  HttpResponseType
} from "api/clients";
import { CCard, CCardBody } from "@coreui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { getReasonPhrase } from "http-status-codes";

import Loading from "components/common/Loading";
import ApiResponseAlert from "components/common/ApiResponseAlert";
import WrappedPage from "components/pages/WrappedPage";
import SCardHeader from "components/common/CardHeader";
import LocationEditForm from "components/pages/locations/EditForm";
import KitTypeGrid from "./KitTypeGrid";

export default function EditLocation() {
  let { id } = useParams() as any;

  const dispatch = useDispatch();
  const [locationsClient] = useState(new LocationsClient());
  const [locationID] = useState(id);
  const [ready, setReady] = useState(false);
  const [location, setLocation] = useState(undefined as LocationDTO | undefined);
  const [actionResult, setActionResult] = useState(
    undefined as ApiResponse | undefined
  );

  let breadcrumbs = [
    {
      to: "/locations",
      label: "Locations"
    },
    {
      label: "Edit Location"
    }
  ];

  useEffect(() => {
    async function getLocationData() {
      let apiLocation = await locationsClient
        .get(locationID)
        .catch((result: ApiException) => {
          setActionResult(
            ApiResponse.fromJS({
              responseType: HttpResponseType.Error,
              messages: [
                "Error getting location to edit: " + getReasonPhrase(result.status)
              ]
            })
          );
        });

      if (apiLocation !== undefined) {
        setLocation(apiLocation.data as LocationDTO);
      }
      setReady(true);
    }

    getLocationData();
  }, [locationsClient, locationID, dispatch]); // only re-run the effect if the role changed

  function onSubmit(data: LocationDTO) {
    locationsClient
      .put(data)
      .then((response: ApiResponse) => {
        setActionResult(ApiResponse.fromJS({
          messages: ["Location Updated"],
          responseType: HttpResponseType.Success
        }));
      })
      .catch((reason: any) => {
        setActionResult(
          ApiResponse.fromJS({
            messages: [reason],
            responseType: HttpResponseType.Error
          })
        );
        console.error(reason);
      });
  }

  if (!ready) {
    return <Loading color="primary" label="Loading Location edit screen"></Loading>;
  }

  return (
    <WrappedPage breadcrumbs={breadcrumbs}>
      <CCard>
        <SCardHeader title="Edit Location"></SCardHeader>
        <CCardBody className="c-body-full">
          {location === undefined || location === null ? (
            <ApiResponseAlert data={actionResult!}></ApiResponseAlert>
          ) : (
            <>
              {actionResult !== undefined && (
                <ApiResponseAlert data={actionResult}></ApiResponseAlert>
              )}
              <LocationEditForm location={location} onSubmit={onSubmit}></LocationEditForm>
            </>
          )}
          <h5>Location Kit Types</h5>
          <KitTypeGrid locationId={id} domLayout="autoHeight"></KitTypeGrid>
        </CCardBody>
      </CCard>
    </WrappedPage>
  );
}
