import {
  LocationDTO,
  LocationsClient,
  ApiResponse,
  HttpResponseType
} from "api/clients";
import { CCard, CCardBody } from "@coreui/react";
import { useState } from "react";

import ApiResponseAlert from "components/common/ApiResponseAlert";
import WrappedPage from "components/pages/WrappedPage";
import SCardHeader from "components/common/CardHeader";
import { useHistory } from "react-router";
import LocationEditForm from "components/pages/locations/EditForm";

export default function AddLocation() {
  const history = useHistory();
  const [locationsClient] = useState(new LocationsClient());
  const [actionResult, setActionResult] = useState(
    undefined as ApiResponse | undefined
  );

  let breadcrumbs = [
    {
      to: "/locations",
      label: "Locations"
    },
    {
      label: "Add Location"
    }
  ];

  function onSubmit(data: LocationDTO) {
    locationsClient
      .post(data)
      .then((response: ApiResponse) => {
        setActionResult(ApiResponse.fromJS({
          messages: ["Location Added"],
          responseType: HttpResponseType.Success
        }));

        if (response.data && "id" in response.data) {
          history.push("/locations/edit/" + response.data.id);
        }
      })
      .catch((reason: any) => {
        setActionResult(
          ApiResponse.fromJS({
            messages: [reason],
            responseType: HttpResponseType.Error
          })
        );
        console.error(reason);
      });
  }

  return (
    <WrappedPage breadcrumbs={breadcrumbs}>
      <CCard>
        <SCardHeader title="Add Location"></SCardHeader>
        <CCardBody>
          {actionResult !== undefined && (
            <ApiResponseAlert data={actionResult}></ApiResponseAlert>
          )}
          <LocationEditForm location={LocationDTO.fromJS({ enabled: true })} onSubmit={onSubmit}></LocationEditForm>
        </CCardBody>
      </CCard>
    </WrappedPage>
  );
}
