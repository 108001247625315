import React, { Component } from "react";
import { CSpinner } from "@coreui/react";

interface LoadingProps {
  label: string;
  color:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | undefined;
}

export default class Loading extends Component<LoadingProps> {
  render() {
    const { label, color } = this.props;

    var colorClass = "text-" + color;

    return (
      <div className="d-flex">
        <div className="loading-container d-flex align-items-center m-auto">
          <CSpinner color={color} className="mr-2" />
          <span className={colorClass}>{label}</span>
        </div>
      </div>
    );
  }
}
