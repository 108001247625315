import { useEffect, useState } from 'react'

import { FilteredReportRequestDTO } from "api/clients";
import { ReportFilterSubmission } from "common/types"
import { SelectOption, SelectOptionGroup } from 'common/selectOption';
import { DateTime } from "luxon"

interface FiltersProps {
  handleSubmit: ReportFilterSubmission
  isSearching: boolean
  initialFilters: FilteredReportRequestDTO
  kitTypes?: SelectOption[]
  locations?: SelectOptionGroup[]
}

export default function FulfilmentFilters({ handleSubmit, isSearching, initialFilters }: FiltersProps) {

  const [filters, setFilters] = useState<FilteredReportRequestDTO | undefined>(undefined);

  useEffect(() => {
    setFilters(FilteredReportRequestDTO.fromJS(initialFilters))
  }, [initialFilters])

  let resetForm = () => {

    setFilters(FilteredReportRequestDTO.fromJS(initialFilters))
  }

  let handleInputChange = (event: any) => {
    const target = event.target;

    var date = target.value.length > 0 ? DateTime.fromISO(target.value) : undefined

    setFilters(FilteredReportRequestDTO.fromJS({
      ...filters,
      [target.name]: date
    }))
  }

  let submitForm = (event: any) => {
    event.preventDefault();

    handleSubmit(filters!);

    return false;
  }


  return (
    <>
    {filters !== undefined && 
    <form onSubmit={submitForm}>
      <div className="row">
        <div className="col col-md-6">
          <div className="form-group form-row">
            <label className="col-form-label col-4" htmlFor="date-from">Date from:</label>
            <div className="col-8">
              <input 
              id="date-from" 
              className="form-control" 
              name="startDate"
              value={filters!.startDate !== undefined ? filters!.startDate!.toISODate() : ""} 
              type="date" 
              onChange={(event) => handleInputChange(event)} />
            </div>
          </div>
        </div>
        <div className="col col-md-6">
          <div className="form-group form-row">
            <label className="col-form-label col-4" htmlFor="date-to">Date to:</label>
            <div className="col-8">
              <input 
              id="date-to" 
              className="form-control" 
              name="endDate"
              value={filters!.endDate !== undefined ? filters!.endDate!.toISODate() : ""}
              type="date" 
              onChange={(event) => handleInputChange(event)} />
            </div>
          </div>
        </div>
      </div>

      <div className="text-right">
        <button type="button" className="btn btn-link" onClick={(e) => resetForm()}>Reset</button>
        <button type="submit" className="btn btn-primary ml-2" disabled={isSearching}>Submit</button>
      </div>
    </form>
  }
  </>
  )
}
