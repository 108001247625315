import { Component } from 'react'
import { CCard, CCardBody } from "@coreui/react";
import WrappedPage from "components/pages/WrappedPage";
import FulfilmentTabs from "./Tabs";
import FulfilmentGrid from "./FulfillmentGrid";
import { FileResponse, FilteredReportRequestDTO, FormSubmissionDTO, KitShipmentStatusClient, KitsOrderedRequestDTO, ShipmentStatus, ShipOrdersDTO, SubmissionSource } from "api/clients";
import FulfilmentFilters from "./Filters";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import DownloadFile from "api/downloadFile";
import { DateTime } from "luxon";
interface KitsMarkedShippedProps {}

interface KitsMarkedShippedState {
  data: FilteredReportRequestDTO
  isSearching: boolean
  gridData: FormSubmissionDTO[] | null
  reasonOpen: boolean
  returnReason: string
  selectedOrders: ShipOrdersDTO[]
}

export default class KitsMarkedShipped extends Component<KitsMarkedShippedProps, KitsMarkedShippedState> {
  
  constructor(props: KitsMarkedShippedProps) {
    super(props);
    this.state = {
      data: new FilteredReportRequestDTO(),
      isSearching: false,
      gridData: null,
      reasonOpen: false,
      returnReason: "",
      selectedOrders: []
    };
  }

  openModal = () => this.setState({ reasonOpen: true });
  closeModal = () => this.setState({ reasonOpen: false }); 

  ExportGirdToCSV = () => {
    var orderReq = new KitsOrderedRequestDTO();
    orderReq.status = [ShipmentStatus.Delivery]
    orderReq.filters = this.state.data;
    (new KitShipmentStatusClient()).getKitsOrderedCsv(orderReq).then((resp: FileResponse | null) => {
      DownloadFile(resp);
    }).catch((err: any) => {
      toast.error("Error getting report, please try again.");
      console.error('Error getting report:', err)
    });
  }

  GetOrders = (filters: FilteredReportRequestDTO) =>{
    var orderReq = new KitsOrderedRequestDTO();
    orderReq.status = [ShipmentStatus.Delivery]
    orderReq.filters = filters;
    this.setState({ data: filters});
    (new KitShipmentStatusClient()).getKitsOrdered(orderReq)
    .then((resp: any | null) => {
        this.setState({gridData: resp})
    }).catch((err: any) => {
        toast.error("Error getting report, please try again.");
        console.log('Error getting report:' + err)
    })
  } 

  MarkReturned = () => {
    (new KitShipmentStatusClient()).updateOrdersStatus(this.state.selectedOrders.map(x => x.orderID), ShipmentStatus.Returned, this.state.returnReason, null, DateTime.now(), false ).then(() => {
      this.GetOrders(this.state.data)
    })
    this.closeModal()
  }

  ToggleSelected = (row: any, RefreshGrid:any ) => {
    if(this.state.selectedOrders.find(x => x.orderID === row.id)){
      this.setState({selectedOrders: this.state.selectedOrders.filter(x => x.orderID !== row.id)})
    }
    else{
      var order = new ShipOrdersDTO();
      order.orderID = row.id;
      order.submissionSource = SubmissionSource[row.submissionSource.replace(/\s/g,'') as any] as unknown as SubmissionSource
      this.setState({selectedOrders: this.state.selectedOrders.concat(order)})
    }
    RefreshGrid()
  }

  SelectAllRowsButton = (RefreshGrid:any) => {
    if (this.state.selectedOrders.length === this.state.gridData?.length){
      this.setState({selectedOrders: []})
    }
    else{
      this.setState({selectedOrders: this.state.gridData?.map(x => new ShipOrdersDTO({ orderID: x.id, 
        submissionSource: x.submissionSource !== undefined ? SubmissionSource[x.submissionSource.replace(/\s/g,'') as any] as unknown as SubmissionSource : SubmissionSource.Unknown,
       }) ) ?? []})
    }

    RefreshGrid();
  }

  CheckIfToggled=(id: number) => {
    return this.state.selectedOrders.find(x => x.orderID === id) !== undefined
  }

  editReason = (e: any) => {
    this.setState({returnReason: e.target.value})
  }

  render() {
    return (
        <WrappedPage breadcrumbs={[{ label: "Fulfilment" }]}>
          <FulfilmentTabs></FulfilmentTabs>
          <CCard>
            <CCardBody className="c-body-full">
            <FulfilmentFilters handleSubmit={this.GetOrders} initialFilters={this.state.data} isSearching={this.state.isSearching}></FulfilmentFilters>
            <div><hr></hr></div>
              <FulfilmentGrid ToggleSelected={this.ToggleSelected} SelectAllRowsButton={this.SelectAllRowsButton} CheckIfToggled={this.CheckIfToggled} orders={this.state.gridData} ExportGirdToCSV = {this.ExportGirdToCSV}isEditable={false} EditAddressGridButton={function (): void | null {
              throw new Error('Function not implemented.');
              }}/>
              {this.state.gridData !== null ? (<div className="text-right mt-2">
                <button type="submit" className="btn btn-primary ml-2" onClick={this.openModal}><i className="cil-check-alt btn-icon mr-2"></i> Mark As Returned</button>
              </div>) : (null)}
            </CCardBody>
          </CCard>
          <Modal show={this.state.reasonOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Reason for Return</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea className="form-control" rows={3} value={this.state.returnReason} onChange={this.editReason}></textarea>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-primary" onClick={this.closeModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={this.MarkReturned}>Submit</button>
            </Modal.Footer>
          </Modal>
        </WrappedPage>
      )
    }
}