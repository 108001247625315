import { Component } from "react";
import { CCardHeader, CLink, CButton } from "@coreui/react";

export interface CardHeaderAction {
  to?: string;
  icon?: string;
  label: string;
  onClick?: Function;
}

interface SCardHeaderProps {
  title: string;
  actionButtons?: CardHeaderAction[];
  [x: string]: any;
}

export default class SCardHeader extends Component<SCardHeaderProps> {
  render() {
    const { title, actionButtons, ...rest } = this.props;

    return (
      <CCardHeader className="d-flex align-items-center" {...rest}>
        {title}
        {actionButtons && (
          <div className="ml-auto">
            <div className="btn-group btn-group-sm">
              {actionButtons.map((x, i) =>
                x.to !== undefined ? (
                  <CLink className="btn btn-outline-primary" to={x.to} key={i}>
                    {x.icon && x.icon.length > 0 && <i className={x.icon}></i>}{" "}
                    {x.label}
                  </CLink>
                ) : (
                  <CButton
                    className="btn btn-outline-primary"
                    key={i}
                    onClick={x.onClick}
                  >
                    {x.icon && x.icon.length > 0 && <i className={x.icon}></i>}{" "}
                    {x.label}
                  </CButton>
                )
              )}
            </div>
          </div>
        )}
      </CCardHeader>
    );
  }
}
