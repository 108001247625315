import React from "react";
import { default as ReactSelect, GroupBase, SingleValue } from "react-select";
import { Controller, FormProvider, RegisterOptions, useForm, useFormContext, UseFormReturn } from "react-hook-form";
import CustomSelectStyles from "./SelectStyle"
import { get } from "lodash"
import { default as ReactDatePicker } from "react-datepicker"
import { IBaseOption } from "common/selectOption";
import { DateTime } from "luxon"
type IForm = {
  defaultValues: any;
  children: any;
  onSubmit: any;
  [x: string]: any;
};

export function Form({ defaultValues, children, onSubmit, ...rest }: IForm) {
  const methods = useForm({ defaultValues, shouldFocusError: false });
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} {...rest}>
        {React.Children.map(children, child => {
          return child.props.name
            ? React.createElement(child.type, {
                ...{
                  ...child.props,
                  key: child.props.name
                }
              })
            : child;
        })}
      </form>
    </FormProvider>
  );
}

type IManagedForm<Model> = {
  children: any;
  onSubmit?: any;
  methods: UseFormReturn<Model>;
  [x: string]: any;
};

export function ManagedForm<Model>({
  children,
  onSubmit,
  methods,
  ...rest
}: IManagedForm<Model>) {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} {...rest}>
        {React.Children.map(children, child => {
          return child.props.name
            ? React.createElement(child.type, {
                ...{
                  ...child.props,
                  key: child.props.name
                }
              })
            : child;
        })}
      </form>
    </FormProvider>
  );
}


type IValidationOutput = {
  name: string;
}

export function ValidationOutput({ name }: IValidationOutput) {
  const methods = useFormContext();
  const { errors } = methods.formState

  let error = get(errors, name);

  return (
    <>
      {error && <span role="alert" className="text-danger">
        {
          error.type === "required" 
            ? "This field is required"
            : error.message
        }
      </span>}
    </>
  );
}

type IInput = {
  name: string;
  rules?: RegisterOptions;
  [x: string]: any;
};

export function Input({ name, rules, ...rest }: IInput) {
  const methods = useFormContext();
  const { errors } = methods.formState;
  let error = get(errors, name);

  return (
    <>
      <input
        className={`form-control ${error ? "border-danger" : ""}`}
        id={name}
        {...methods.register(name, rules)}
        {...rest}
      />
      <ValidationOutput name={name} />
    </>
  );
}

type IWrappedInput = {
  label: string;
  name: string;
  rules?: RegisterOptions;
  [x: string]: any;
};

export function WrappedInput({ name, label, rules, ...rest }: IWrappedInput) {
  return (
    <div className="form-row form-group">
      <label className={`col-form-label col-4 ${rules && rules.required ? 'required' : ''}`} htmlFor={name}>
        {label}
      </label>
      <div className="col-8">
        <Input name={name} rules={rules} {...rest} />
      </div>
    </div>
  );
}

export function Textbox({ name, rules, ...rest }: IInput) {
  const methods = useFormContext();
  const { errors } = methods.formState;
  let error = get(errors, name);

  return (
    <>
      <textarea
        className={`form-control ${error ? "border-danger" : ""}`}
        id={name}
        {...methods.register(name, rules)}
        {...rest}
      ></textarea>
      <ValidationOutput name={name} />
    </>
  );
}

export function WrappedTextbox({ name, label, rules, ...rest }: IWrappedInput) {
  return (
    <div className="form-group">
      <label className={`form-label ${rules && rules.required ? 'required' : ''}`} htmlFor={name}>
        {label}
      </label>
      <div>
        <Textbox name={name} {...rest} />
      </div>
    </div>
  );
}

type ICheckbox = {
  checked: boolean;
  name: string;
  label: string;
  id: string;
  labelClasses?: string;
  [x: string]: any;
};

export function Checkbox({ checked, name, id, label, labelClasses, ...rest }: ICheckbox) {
  const methods = useFormContext();

  return (
    <div className="form-check">
      <input
        type="checkbox"
        id={id}
        className="form-check-input"
        defaultChecked={checked}
        {...methods.register(name)}
        {...rest}
      />
      <label className={"form-check-label " + labelClasses} htmlFor={id}>
        {label}
      </label>
    </div>
  );
}

export function CleanCheckboxArrayValue(values: any[]): number[] {
  return values
    .filter(x => x !== false && !isNaN(parseInt(x)))
    .map(x => parseInt(x));
}

type ISelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  name: string;
  options: Option[];
  value?: any;
  isMulti?: IsMulti;
  group?: Group;
  disabled?: boolean;
  rules?: RegisterOptions;
};

export function Select<
  Option extends IBaseOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  options,
  value,
  isMulti,
  group,
  rules,
  disabled,
  ...rest
}: ISelect<Option, IsMulti, Group>) {
  const methods = useFormContext();
  const { errors } = methods.formState;
  let error = get(errors, name);

  return (
    <>
    <Controller
        control={methods.control}
        defaultValue={value}
        name={name}
        rules={rules}
        render={({ field: { onChange, value, ref } }) => (
          <ReactSelect
              ref={ref}
              options={options}
              value={options.find(c => c.value === value)}
              onChange={(newValue: SingleValue<Option>) => onChange(newValue!.value)}
              isDisabled={disabled}
              classNamePrefix={error ? "error" : ""}
              styles={CustomSelectStyles}
              {...rest}
          />
        )}
    />
    <ValidationOutput name={name} />
    </>
  );
}

type IWrappedSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  label: string;
  name: string;
  options: Option[];
  value?: any;
  isMulti?: IsMulti;
  group?: Group;
  rules?: RegisterOptions;
  disabled?: boolean;
  [x: string]: any;
};

export function WrappedSelect<
  Option extends IBaseOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  label,
  options,
  value,
  isMulti,
  group,
  rules,
  disabled,
  ...rest
}: IWrappedSelect<Option, IsMulti, Group>) {
  return (
    <div className="form-row form-group">
      <label className={`col-form-label col-4 ${rules && rules.required ? 'required' : ''}`} htmlFor={name}>
        {label}
      </label>
      <div className="col-8">
        <Select name={name} options={options} value={value} rules={rules} disabled={disabled} {...rest} />
      </div>
    </div>
  );
}

type IDatePicker = {
  name: string;
  rules?: RegisterOptions;
  [x: string]: any;
};

export function DatePicker({ name, rules, ...rest }: IDatePicker) {
  const methods = useFormContext();
  const { errors } = methods.formState;
  let error = get(errors, name);
  return (
    <>
      <Controller
        control={methods.control}
        name={name}
        rules={rules}
        
        render={({ field: { onChange, value, ref } }) => {
          let jsDateValue = value
          if(value !== undefined && value.isLuxonDateTime) {
            jsDateValue = value.invalid == null
              ? new Date(value.c.year, value.c.month - 1, value.c.day, value.c.hour, value.c.minute, value.c.second, value.c.millisecond)
              : undefined
          }
          
          return (
            <ReactDatePicker
              className={`form-control ${error ? "border-danger" : ""}`}
              dateFormat="yyyy-MM-dd"
              selected={jsDateValue}
              onChange={(newJsDateValue: Date) => {
                onChange(DateTime.fromJSDate(newJsDateValue))
              }}
            />
        )}}
      />
      <ValidationOutput name={name} />
    </>
  );
}

type IWrappedDatePicker = {
  label: string;
  name: string;
  [x: string]: any;
};

export function WrappedDatePicker({ name, label, ...rest }: IWrappedDatePicker) {
  return (
    <div className="form-row form-group">
      <label className={`col-form-label col-4 ${rest.rules && rest.rules.required ? 'required' : ''}`} htmlFor={name}>
        {label}
      </label>
      <div className="col-8">
        <DatePicker name={name} {...rest} />
      </div>
    </div>
  );
}