const customStyles = {
  control: (provided) => ({
    ...provided,
    '&.error__control': {
      border: '1px solid #e55353',
    },
  }),
}

export default customStyles
