import {
  LAYOUT_SET_BREADCRUMBS,
  LAYOUT_SET_SIDEBAR_SHOW,
  Breadcrumb,
  LayoutActionTypes
} from "./types";

// TypeScript infers that this function is returning SetBreadcrumbsAction
export function setBreadcrumbs(breadcrumbs: Breadcrumb[]): LayoutActionTypes {
  return {
    type: LAYOUT_SET_BREADCRUMBS,
    breadcrumbs: breadcrumbs
  };
}

export function setSidebarShow(sidebarShow: boolean): LayoutActionTypes {
  return {
    type: LAYOUT_SET_SIDEBAR_SHOW,
    sidebarShow: sidebarShow
  };
}

export const actionCreators = {
  setBreadcrumbs
};
